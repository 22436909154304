<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col>
        <v-card
          color="rgb(255, 255, 255, 0.8)"
        >
          <v-card-title>{{ $t('notFound.title')}}</v-card-title>
          <v-card-subtitle>{{ $t('notFound.subtitle')}}</v-card-subtitle>
          <v-card-text>{{ $t('notFound.text')}}</v-card-text>
          <v-container>
            <v-btn
              color="primary"
              @click="redirectToProFemina"
            >
              <v-icon
                color="secondary"
              >
                mdi-home
              </v-icon>
              profemina Homepage
            </v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { redirectProfeminaOrigin } from '@/utils/redirect';

export default {
  name: 'NotFound',
  methods: {
    redirectToProFemina() {
      redirectProfeminaOrigin('de-de');
    },
  },
};
</script>
